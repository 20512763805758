import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import ScrollspyNav from 'react-scrollspy-nav';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import '../../../dynamic-pages/hsr-character-dynamic.scss';
import './hsr-guides.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { HSRSkill } from '../../../modules/hsr/skills/hsr-skill';
import { HSRStat } from '../../../modules/hsr/common/components/hsr-stat';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import {
  faBriefcaseMedical,
  faFlask,
  faHandFist
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '../../../modules/common/components/box';

const SRGuidesRappa: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rappa - First Impressions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_rappa.jpg"
            alt="Rappa - First impressions"
          />
        </div>
        <div className="page-details">
          <h1>Rappa - First Impressions</h1>
          <h2>
            Check the early access first impressions of Rappa - a new character
            coming soon to Honkai: Star Rail!
          </h2>
          <p>
            Last updated: <strong>16/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content character-hsr-v2 special-guide">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Disclaimer" />
              <Alert variant="primary alert-red">
                <p>
                  The information you can find in this article{' '}
                  <strong>
                    come from the Creator Experience server and as such are
                    subject to change
                  </strong>{' '}
                  - so while you can check out Rappa's kit details a bit early,
                  please keep that in mind. A full guide for Rappa will be
                  released as usual once the patch introducing her goes live.
                </p>
              </Alert>
              <p>
                Also, here's a <strong>special code</strong> to celebrate
                Rappa's reveal!
              </p>
              <div className="codes">
                <Box isCentered>
                  <p className="code">KFWJG6L3N8M1V</p>
                  <p className="date">Released on 16.10.2024</p>
                </Box>
              </div>
              <p>
                You can also use this{' '}
                <a
                  href="https://hsr.hoyoverse.com/gift?code=KFWJG6L3N8M1V"
                  target="_blank"
                  rel="noreferrer"
                >
                  link here
                </a>{' '}
                to redeem the code in your web browser!
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Introduction" />
              <StaticImage
                src="../../../images/starrail/robin/intro.webp"
                alt="Rappa"
              />
              <h6>Rappa's Profile</h6>
              <p>
                <strong className={`Imaginary`}>Rappa</strong> is a{' '}
                <strong className={`rarity-5`}>5★</strong> character from the{' '}
                <strong className={`Imaginary`}>Imaginary</strong> element who
                follows the{' '}
                <strong className={`Hunt`}>Path of The Erudition</strong>. A
                peculiar girl who appears in Penacony like a flashbang at the
                darkest hour of night, identifying herself as a ninja and
                attributing everything in the world to "ninjutsu." Upholding the
                recitation of ninja mantra, creating Dazzling Ninja Seals, and
                mastering ninja techniques, the Way of the Ninja involves rap,
                graffiti, and comics. Through rigorous self-discipline, she
                roams the stars, upholding justice and righteousness. As a
                member of the Galaxy Rangers, she relentlessly pursues the
                villain known as Evil Ninja Osaru, chasing them to the very edge
                of the Cosmos.
              </p>
              <h6>Rappa's Intro</h6>
              <p>
                <strong>
                  Rappa is a strong Erudition Break DPS unit who decimates AoE
                  content
                </strong>
                . She gains native Weakness Break Efficiency and bonus Break
                Effect, and has Weakness ignoring Toughness damage. She ramps up
                relatively quickly and can continue to deal both Break and Super
                Break DMG to enemies after they are broken.{' '}
                <strong>
                  She is even able to increase the Break DMG enemies take by up
                  to 10% for simply existing on the team, giving her good
                  synergy with the rest of the Break archetype.
                </strong>{' '}
                She has an immense amount of potential, but currently doesn't
                have the means to reach it outside of Pure Fiction.
              </p>
              <p>
                I guess all Galaxy Rangers have some sort of quirk to them,
                because Rappa is a graffiti ninja and... quite invested in rap
                and manga as well? I guess it's not all in her head since she
                does casually summon giant shurikens to throw at her enemies.
                Rappa is another addition to the ever growing Break archetype,
                and this isn't exactly an archetype that's lacking for great
                units.{' '}
                <strong>
                  Luckily, Rappa does have her place in it for those interested
                  in pulling for her.
                </strong>
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Traces and Eidolons" />
              <p>
                First, let's go over Rappa's kit - so stats, skills, major
                traces and minor traces - and explain how they work.
              </p>
              <h6>Rappa's Stats (level 80)</h6>
              <Row xs={1} xxl={4} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">HP</div>
                    <div className="details">1087</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">DEF</div>
                    <div className="details">461</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">ATK</div>
                    <div className="details">718</div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Speed</div>
                    <div className="details">96</div>
                  </div>
                </Col>
              </Row>
              <h6>Rappa's Skills</h6>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131701"
                        maxLevel={7}
                        element="Imaginary"
                        gen="20"
                        break_primary="10"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Well, nothing much can be said about Rappa's basic attack - like
                majority of the characters, it's a simple one-target attack with
                average damage and you will rarely use it.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131709"
                        maxLevel={7}
                        element="Imaginary"
                        gen="20"
                        break_primary="10"
                        break_secondary="5"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                The Enhanced Basic Attack becomes available after Rappa uses her
                Ultimate. And it's three attacks in one action; her first two
                hits of the Enhanced Basic ATK are Blast AoEs, dealing up to
                100% ATK to the primary foe and 50% to adjacents, and the final
                hit strikes all enemies on the field for up to 100% ATK.
              </p>
              <p>
                This doesn't restore Skill Points, but since it's usable up to 3
                actions, it makes Rappa a bit more SP efficient than other
                characters. The Enhanced Basic ATK can also reduce enemy
                Toughness regardless of Weakness at 50% of the original
                effectiveness. Each swing of the Enhanced Basic has its own
                associated Toughness DMG and small Energy gain, and the total
                single-target Toughness you can deal is quite significant,
                especially factoring in Rappa's native Weakness Break
                Efficiency.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131702"
                        maxLevel={12}
                        element="Imaginary"
                        gen="30"
                        break_primary="10"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Rappa's Skill is a standard Erudition Skill, hitting all enemies
                for up to 120% ATK. There's nothing really that noteworthy about
                this besides the fact that it's useful for Energy regen.{' '}
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131703"
                        maxLevel={12}
                        element="Imaginary"
                        gen=""
                        break_primary=""
                        break_secondary=""
                        ult_cost={140}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                After using the Ultimate, Rappa enters the Sealform state and
                will immediately gain an Extra Turn, alongside 3 points of
                Chroma Ink. During this state, Rappa has 50% increased Weakness
                Break Efficiency and up to 30% additional Break Effect. This
                costs an above-average 140 Energy. The Sealform state Enhances
                her Basic ATK and locks her out of her Ultimate and Skill. After
                each Enhanced Basic, Rappa consumes 1 point of Chroma Ink, and
                when there are no more points of Chroma Ink, she ends the
                Sealform state.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131704"
                        maxLevel={12}
                        element="Imaginary"
                        gen=""
                        break_primary=""
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                'This is all well and good but where's the damage? This is
                supposed to be a Break unit!' Astute observation, friend, and
                the secret lies with Rappa's Talent. Whenever an enemy's
                Weakness is Broken, Rappa gains 1 point of Charge, up to 10
                points total. Additionally, upon using the third and final
                strike of her Enhanced Basic ATK, Rappa will deal Break DMG
                equal to 60% of her Break DMG to all enemies hit. This DMG can
                ignore Weakness Types to deal some small additional Toughness
                DMG, consuming all accumulated Charge in the process. For each
                point of Charge consumed, the Break DMG multiplier of this
                Talent increases by up to 50% per point of Charge, and will also
                increase the Weakness ignoring Toughness DMG as well. Breaking
                an enemy with this DMG will result in an Imaginary Break.
              </p>
              <div className={`skills Imaginary`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <HSRSkill
                        id="131707"
                        maxLevel={1}
                        element="Imaginary"
                        gen=""
                        break_primary="6"
                        break_secondary=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Rappa's Major Traces</h6>
              <div className={`skills traces Imaginary`}>
                <Row xs={1} xl={2} xxl={3}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>
                          A2
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Ninjutsu Inscription: Sky High
                          </p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Imaginary`}>
                        When the Weakness of an elite-level or higher enemy is
                        broken, Rappa additionally gains <b>1</b> point(s) of
                        Charge and regenerates <b>10</b> Energy.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>
                          A4
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Ninjutsu Inscription: Sea Echo
                          </p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Imaginary`}>
                        While in the Sealform state, after Rappa uses Enhanced
                        Basic ATK to deal DMG to a Weakness Broken enemy target,
                        converts the Toughness Reduction from this instance of
                        DMG to <b>1</b> instance of <b>60%</b> Super Break DMG.
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>
                          A6
                          <span>Major trace</span>
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Ninjutsu Inscription: Withered Leaf
                          </p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring Imaginary`}>
                        When an enemy target becomes Weakness Broken, increases
                        the Break DMG taken by <b>2%</b>. If Rappa's current ATK
                        is higher than 2400, for every 100 excess ATK,
                        additionally increases this value by <b>1%</b>, up to a
                        max additional increase of <b>8%</b>. This effect lasts
                        for <b>2</b> turn(s).
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                Her Bonus Abilities are extremely useful and here's what they
                do:
              </p>
              <ul>
                <li>
                  Her A2 will give Rappa an additional point of Charge and an
                  extra 10 Energy whenever an Elite-level or higher enemy's
                  Weakness is Broken, giving her a nice extra bump to her setup.
                </li>
                <li>
                  A4 will grant Rappa the ability to deal Super Break DMG with
                  her Enhanced Basic ATK; 60% Super Break DMG per instance of
                  damage from the Enhanced Basic, for a total of 180% Super
                  Break DMG. That's pretty good considering this is in addition
                  to the direct Break DMG her third hit will be doing, and makes
                  for some very colourful numbers explosions.
                </li>
                <li>
                  A6 is another stat converter Trace. Whenever an enemy is
                  Broken, Rappa increases their Break DMG taken by 2%. If
                  Rappa's AT is higher than 2400, for every 100 ATK over that,
                  the value of this vulnerability increases by 1%, up to an
                  additional 8%. This results in a total 10% increased Break DMG
                  taken at 3200 ATK. This is a pretty impressive boost to have
                  for the low cost of just existing and doing what you were
                  going to be doing anyway, but the nature of the boost makes it
                  hard to maximise; Rappa is a Break unit, so building for ATK
                  is a lot less desirable than building Break Effect and SPD, so
                  this is unlikely to be fully maximised without 5-star Light
                  Cones.
                </li>
              </ul>
              <h6>Rappa's Minor Traces</h6>
              <div className="smaller-traces">
                <Row xs={1} xxl={3}>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="ATK%" />{' '}
                      <span className="value">+28%</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="Speed" /> <span className="value">9</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="box trace">
                      <HSRStat stat="Break Effect" />{' '}
                      <span className="value">+13.3%</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Eidolons" />
              <p>
                Now let's check Rappa's Eidolons and how they affect her damage
                output.
              </p>
              <h6>Rappa's Eidolons</h6>
              <div className="skills eidolons">
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E1</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Returned Is the Revenant With No Ferry Toll
                          </p>
                          <p className="skill-type">Eidolon 1</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          During the "Sealform" state entered by using Ultimate,
                          DMG dealt by Rappa ignores 15% of the targets' DEF.
                          After exiting the "Sealform" state, regenerates 20
                          Energy.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E2</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Free Is the Mind Enlightened by Haikus
                          </p>
                          <p className="skill-type">Eidolon 2</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          The Enhanced Basic ATK's first 2 hits have their
                          Toughness Reduction against the one designated enemy
                          increased by 50%.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E4</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Lost Is the Nindō Devoured by Time
                          </p>
                          <p className="skill-type">Eidolon 4</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          While in the "Sealform" state, increases all allies'
                          SPD by 12%.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Imaginary`}>E6</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Righteous Is the Wrath That Spares No Evil
                          </p>
                          <p className="skill-type">Eidolon 6</p>
                        </div>
                      </div>
                      <div className={`skill-with-coloring eidolon Imaginary`}>
                        <p>
                          When battle starts, Rappa gains 5 point(s) of her
                          Talent's Charge, whose upper limit is 5 point(s).
                          After launching the third hit of "Ningu: Demonbane
                          Petalblade," gains 5 point(s) of Charge.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h6>Eidolons reviewed</h6>
              <ul>
                <li>
                  <strong>E1</strong> in particular is quite nice, letting Rappa
                  ignore 15% DEF during her Sealform state, and then
                  regenerating an additional 20 Energy once it ends. This makes
                  her gameplay more fluid as you often run into energy issues
                  with Rappa, needing just a little bit more to chain her
                  Ultimates.
                </li>
                <li>
                  <strong>E2</strong> increases the Toughness DMG of the first
                  two hits of her Enhanced Basic against their main target by
                  50%.
                </li>
                <li>
                  <strong>E4</strong> increases all allies' SPD by 12% during
                  the Sealform state. It's a pretty simple one, but Break teams
                  love Speed, so it's quite helpful.
                </li>
                <li>
                  <strong>E6</strong> jump-starts her Talent by giving her 5
                  points of Charge at the beginning of combat, and then
                  subsequently refunding 5 points of Charge after the third hit
                  of each Enhanced Basic.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Relics and Light Cones" />
              <div className={`section-build Imaginary `}>
                <div className="build-stats">
                  <h6 className="with-margin">Recommended Main and Substats</h6>
                  <Row xs={1} xl={2} xxl={2} className="main-stats">
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Body</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="ATK%" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Feet</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Speed" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Planar Sphere</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="ATK" />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="box">
                        <div className="stats-header">
                          <span>Link Rope</span>
                        </div>
                        <div className="list-stats">
                          <HSRStat stat="Break Effect" />
                          <span className="order">&gt;=</span>
                          <HSRStat stat="Energy Regen Rate" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="box sub-stats">
                        <span>Substats:</span>
                        <p>
                          SPD (Until Desired Breakpoint) &gt; Break Effect &gt;
                          ATK%
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <br />
                <h6>Recommended Endgame Stats (while buffed in combat)</h6>
                <ul>
                  <li>
                    HP: <strong>2800+</strong>
                  </li>
                  <li>
                    DEF: <strong>800+</strong>
                  </li>
                  <li>
                    ATK: <strong>3200</strong> (fully buffed in combat, but it
                    is optional - Speed and BE are more important)
                  </li>
                  <li>
                    Break Effect: <strong>160%-240%+</strong> (before team buffs
                    - if you're using ER rope, you won't reach it though)
                  </li>
                  <li>
                    SPD: <strong>145-150+</strong>
                  </li>
                </ul>
                <hr />
                <p>More info about the stats:</p>
                <ul>
                  <li>
                    <strong>ER vs Break Effect Rope</strong>:
                  </li>
                  <ul>
                    <li>
                      Both ER and BE% Ropes are viable on Rappa and which is
                      stronger will depend on the combat scenario you find
                      yourself in. If equipping an ER rope can consistently
                      shorten Rappa's rotation by an action, it's well worth
                      using. Alternatively if you're already executing 3 action
                      rotations (pure Enhanced Basic Spam) with Rappa or aren't
                      confident you can reach the next action breakpoint
                      consistently Break Effect% rope may be a better choice.
                    </li>
                  </ul>
                  <li>
                    <strong>Speed</strong>:
                  </li>
                  <ul>
                    <li>
                      Being a Break Character, Rappa has limited scaling options
                      on her gear outside of Break Effect% and raw SPD. Because
                      of this it's no surprise that building Speed is a top
                      priority for her but how much is needed? Unfortunately,
                      the answer to that question will vary depending on your
                      Relics, team and Cone choices. Rappa has multiple strong
                      Relic options sporting Speed modifiers - many of her
                      strongest team members have Speed-based boosts or access
                      to action advances of their own and her top 3 Cones also
                      all have Speed boosts and action advances with varying
                      levels of uptime.
                    </li>
                    <li>
                      Because of this pinning down exactly how much Speed to
                      build on Rappa is difficult. As a general rule aiming for
                      145-150 + Speed is recommended. This range activates the
                      Talia Planar set and puts you in striking distance of the
                      powerful 160 Speed breakpoint with 1 strong action/Speed
                      boost or a combination of weaker ones.
                    </li>
                  </ul>
                  <li>
                    <strong>ATK</strong>:
                  </li>
                  <ul>
                    <li>
                      Break characters usually derive no value from ATK as a
                      stat but Rappa has a bonus ability that changes that. On
                      breaking enemies she applies a debuff that increases break
                      damage taken by 2% and for every 100 ATK she has exceeding
                      2400 this debuff increases by an additional 1% up to 8%
                      additional (3200 ATK) for a total of 10%. Maxing this
                      debuff is nice to have but not essential as it stacks
                      additively and is diluted by other sources of damage taken
                      increases - which are fairly common on Break-based
                      supportive characters and sustains like Lingsha and
                      Gallagher. Aim for 3200 ATK (or as high as you can manage)
                      but only if you can do so without sacrificing too many
                      other sub stats such as Speed and Break Effect%.
                    </li>
                  </ul>
                </ul>
                <div className="build-relics">
                  <h6>Recommended Relic Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Iron Cavalry Against the Scourge"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Best generic damage option for Rappa in traditional
                        Super Break compositions
                      </p>
                    </div>
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>91.3%</p>
                      </div>
                      <HSRRelicSet
                        name="Thief of Shooting Meteor"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Alternative option to Iron Cavalry, trading damage for
                        additional Energy generation which can be the difference
                        in achieving a better action breakpoint in combat.
                        Specifically powerful in Pure Fiction or against Exo
                        Toughness where there are many enemies to break.
                      </p>
                    </div>
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>91%</p>
                      </div>
                      <div className="double-set">
                        <HSRRelicSet
                          name="Iron Cavalry Against the Scourge"
                          compactMode
                          comment="2PC"
                        />
                        <HSRRelicSet
                          name="Thief of Shooting Meteor"
                          compactMode
                          comment="2PC"
                        />
                      </div>
                    </div>
                    <div className="information">
                      <p>
                        A decent temporary option if you're still farming Iron
                        Cavalry or another dedicated set for Rappa.
                      </p>
                    </div>
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>-</p>
                      </div>
                      <HSRRelicSet
                        name="Eagle of Twilight Line"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Option for those looking to trade raw damage for massive
                        Speed-tuning potential on Rappa. Especially potent when
                        Rappa is able to execute the coveted 3-action rotation.
                        seriously increasing her action count in fights. Heavily
                        reliant on specific stat tuning for this set to perform
                        well in endgame.
                      </p>
                    </div>
                  </div>
                  <h6 className="with-margin">Recommended Planetary Sets</h6>
                  <div className="detailed-cones moc extra planar">
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>100%</p>
                      </div>
                      <HSRRelicSet
                        name="Talia: Kingdom of Banditry"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Best all around set for Rappa with no strings attached
                        outside of its 145 speed requirement which you should be
                        building towards anyways in her standard build
                      </p>
                    </div>
                    <div className={`single-cone Imaginary`}>
                      <div className={`percentage default`}>
                        <p>-</p>
                      </div>
                      <HSRRelicSet
                        name="Forge of the Kalpagni Lantern"
                        compactMode
                        comment="4PC"
                      />
                    </div>
                    <div className="information">
                      <p>
                        Alternative to Talia with higher highs but lower lows
                        due to its Break Effect bonus being conditional. Against
                        Fire-weak enemies outperforms Talia (104.46%) but
                        against non-Fire weak enemies - which you'll be facing a
                        good amount of the time due to Rappa being Imaginary -
                        falls behind (96.31%).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="build-cones">
                  <h6>Recommended Light Cones</h6>
                  <>
                    <div className="detailed-cones moc">
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>119.52%</p>
                        </div>
                        <HSRLightCone
                          slug="ninjutsu-inscription-dazzling-evilbreaker"
                          mode="profile"
                          superImp="1"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Best option for Rappa by far. Offers the most damage,
                          enough base Attack to allow for easy trace ATK cap, an
                          incredible action advancement and most importantly
                          allows her to access her Ultimate much more quickly at
                          the start of fights. The more consistently you can
                          execute lower action rotations with Rappa (ideally 3
                          action rotations) the better this Cone will perform,
                          due to you gaining greater access to the action
                          advancement(calculations use a 4 action rotation).
                        </p>
                      </div>
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>100%</p>
                        </div>
                        <HSRLightCone
                          slug="after-the-charmony-fall"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          A solid alternative to Rappa's signature Cone. Grants
                          an incredibly consistent Speed Boost and Break Effect%
                          with no downsides.
                        </p>
                      </div>
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>95.19%</p>
                        </div>
                        <HSRLightCone
                          slug="eternal-calculus"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Best F2P option for Rappa, offering a strong ATK%
                          boost, ensuring you'll always satisfy the requirements
                          of her bonus ability and a strong Speed bonus when
                          consistently attacking 3 or more enemies at once. Not
                          as flexible as her signature or After the Charmony
                          Fall, but its bonuses will be consistently active in
                          her best mode Pure Fiction and sometimes active in
                          other modes in AoE heavy encounters.
                        </p>
                      </div>
                      <div className={`single-cone Imaginary`}>
                        <div className="percentage">
                          <p>-</p>
                        </div>
                        <HSRLightCone
                          slug="make-the-world-clamor"
                          mode="profile"
                          superImp="5"
                        />
                      </div>
                      <div className="information">
                        <p>
                          Option for those without Rappa's signature but with a
                          desire to access an early Ultimate in fights. Lacks
                          the bonuses of other non-situational Cones listed
                          above.
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Calculations" />
              <p>
                For Rappa calculations head to her profile and check the{' '}
                <strong>Calculations tab</strong>.
              </p>
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="rappa" enablePopover />
              </div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Teams" />
              <div className={`character-teams box Imaginary`}>
                <div className="team-header">
                  <p>Best Rappa Team (Premium)</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="rappa" enablePopover />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="trailblazer-imaginary"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="ruan-mei"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="lingsha"
                          enablePopover
                        />
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-header">
                  <p>Budget Rappa Gaming</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter mode="icon" slug="rappa" enablePopover />
                      </div>
                      <div className="role dps">
                        <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="trailblazer-imaginary"
                          enablePopover
                        />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main multi">
                        <HSRCharacter mode="icon" slug="asta" enablePopover />
                        <HSRCharacter mode="icon" slug="pela" enablePopover />
                      </div>
                      <div className="role amplifier">
                        <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
                      </div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <HSRCharacter
                          mode="icon"
                          slug="gallagher"
                          enablePopover
                        />
                      </div>
                      <div className="role sustain">
                        <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                        Sustain
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="What's Next?" />
              <p>
                That's the end of the early impressions article for Rappa and we
                hope you did enjoy it! As we mentioned in the introduction
                section,{' '}
                <strong>
                  full Rappa guide will be released and placed on her profile
                  once she's properly available in the game
                </strong>{' '}
                and then she will be also added to our tier list.
              </p>
              <p>Till then!</p>
              <StaticImage
                src="../../../images/starrail/robin/outro.webp"
                alt="Rappa"
              />
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Disclaimer</a>
                </li>
                <li>
                  <a href="#section-1">Introduction</a>
                </li>
                <li>
                  <a href="#section-2">Traces</a>
                </li>
                <li>
                  <a href="#section-3">Eidolons</a>
                </li>
                <li>
                  <a href="#section-4">Relics and Light Cones</a>
                </li>
                <li>
                  <a href="#section-5">Calculations</a>
                </li>
                <li>
                  <a href="#section-6">Synergy and Teams</a>
                </li>
                <li>
                  <a href="#section-7">What's Next?</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SRGuidesRappa;

export const Head: React.FC = () => (
  <Seo
    title="Rappa - First Impressions | Honkai: Star Rail | Prydwen Institute"
    description="Check the early access first impressions of Rappa - a new character coming soon to Honkai: Star Rail!"
  />
);
